<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    />
    <b10-page-content
      :padding="2"
    >
      <v-card>
        <v-card-title>Exportar BD</v-card-title>
        <v-card-text>
          <v-btn
            class="mb-2"
            block
            color="primary"
            @click.stop="exportarBD"
          >
            <v-icon
              left
            >
              {{ $vuetify.icons.values.download }}
            </v-icon> Completa
          </v-btn>
          <v-btn
            class="mb-2"
            block
            color="primary"
            @click.stop="exportarBDCambios"
          >
            <v-icon
              left
            >
              {{ $vuetify.icons.values.download }}
            </v-icon> Solo cambios
          </v-btn>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-title>Importar BD</v-card-title>
        <v-card-text>
          <v-file-input
            v-model="jsonFile"
            class="mb-2"
            label="Fichero base de datos"
            show-size
          />
          <v-btn
            class="mb-2"
            block
            color="primary"
            @click.stop="importarBD"
          >
            <v-icon
              left
            >
              {{ $vuetify.icons.values.upload }}
            </v-icon> Importar BD
          </v-btn>
        </v-card-text>
      </v-card>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { openDB } from 'idb'
import { saveAs } from 'file-saver'
import { basePageMixin } from '@/mixins/basePageMixin'

export default {
  mixins: [basePageMixin],
  data () {
    return {
      databaseVersion: 0,
      db: null,
      jsonFile: [],
    }
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Administrador BD local'
      // cerrar bd para poder acceder directamente a IndexedDB
      await this.closeDB()
      await this.abrirBD()
    },
    async abrirBD () {
      this.db = await openDB('beta10')
      this.$alert.showSnackbarSuccess('Base de datos abierta')
    },
    async downloadClientSide (jsonData) {
      // https://stackoverflow.com/a/13779352
      saveAs(
        new Blob([JSON.stringify(jsonData)], {type : 'text/html'}),
        'data.json'
      )
    },
    async exportarBD () {
      let jsonData = {}
      for (let objectstore of this.db.objectStoreNames) {
        let tx = this.db.transaction(objectstore, 'readonly')
        jsonData[objectstore] = await this.db.getAll(objectstore)
        await tx.done
      }
      await this.downloadClientSide(jsonData)
      this.$alert.showSnackbarSuccess('Base de datos exportada')
    },
    async exportarBDCambios () {
      let jsonData = {}
      const objectstore = 'sync'
      let tx = this.db.transaction(objectstore, 'readonly')
      jsonData[objectstore] = await this.db.getAll(objectstore)
      await tx.done
      await this.downloadClientSide(jsonData)
      this.$alert.showSnackbarSuccess('Base de datos de cambios exportada')
    },
    async importarBD () {
      if (this.jsonFile && this.jsonFile.name) {
        this.$loading.showManual('Importando BD')
        try {
          for (let objectstore of this.db.objectStoreNames) {
            let tx = this.db.transaction(objectstore, 'readwrite')
            this.$loading.setMessage(`Vaciando ${objectstore}`)
            await this.db.clear(objectstore)
            await tx.done
          }
          const reader = new FileReader()
          reader.readAsText(this.jsonFile, 'UTF-8')
          reader.onload = async (evt) => {
            const jsonData = JSON.parse(evt.target.result)
            for (const objectstore in jsonData) {
              this.$loading.setMessage(`Importando ${objectstore}`)
              let tx = this.db.transaction(objectstore, 'readwrite')
              for (const record of jsonData[objectstore]) {
                await tx.store.add(record)
              }
              await tx.done
            }
          }
        } finally {
          this.jsonFile = []
          this.$loading.hide()
        }
        this.$alert.showSnackbarSuccess('Base de datos importada')
      } else {
        this.$alert.showSnackbarError('Debes seleccionar un fichero de base de datos (data.json)')
      }
    }
  },
}
</script>
